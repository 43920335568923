const routes = [
    {
        path: '/house',
        component: () => import(/* webpackChunkName: "group-house" */ '@/App.vue'),
        children: [
            {
                path:'',
                component: () => import(/* webpackChunkName: "group-house" */ '@/views/PublicIndex.vue'),
                meta: { noGuard: true }
            },
            {
                path:'links',
                component: () => import(/* webpackChunkName: "group-house" */ '@/views/PublicLinks.vue'),
                meta: { noGuard: true }
            },
            {
                path:'links/opening/door',
                component: () => import(/* webpackChunkName: "group-house" */ '@/views/PublicHouseOpening.vue'),
                meta: { noGuard: true }
            },
            {
                path:'links/opening/window',
                component: () => import(/* webpackChunkName: "group-house" */ '@/views/PublicHouseOpeningWindow.vue'),
                meta: { noGuard: true }
            },
            {
                path:'links/organizations/:categoryCode',
                component: () => import(/* webpackChunkName: "group-house" */ '@/views/PublicLinksOrganizations.vue'),
                meta: { noGuard: true },
                props: true
            },
        ]
    }
]

export default routes