<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト(住宅版)'"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="true"
        :is-house="true"
        :is-visible-breadcrumb="true"
        :breadcrumb-category="'躯体の外皮性能等 - 開⼝部（仕様に基づく） - 大部分が不透明材料で構成されている開口部（ドア等）'"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">仕様に基づく開⼝部の性能</h2>
            </div>
            <div class="uq_opening_wrapper">
                <div class="uq_opening">
                    <h4>大部分が不透明材料で構成されている開口部（ドア等）</h4>
                    <div class="links">
                        <a
                            href="https://www.jsma.or.jp/Portals/0/images/useful/technology/20-0502.pdf"
                            target="_blank"
                        >
                            <p>
                                「建具とガラスの組み合わせ」による開⼝部の熱貫流率（⼀般社団法⼈⽇本サッシ協会より）<br>
                                <span class="el_a__pdf">
                                https://www.jsma.or.jp/Portals/0/images/useful/technology/20-0502.pdf
                                </span>
                            </p>
                        </a>
                    </div>
                    <app-button
                        name="事業者リンク（ドア等）"
                        @click="showLinks('door')"
                        ref="jest-opening-door"
                        class="wd_200px"
                    />
                </div>
            </div>
            
            <div class="ly_buttonBox">
                <app-button
                    name="戻る"
                    class="cancel"
                    @click="moveTo('/house/links')"
                    ref="jest-opening-cancel"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P013 開口部一覧
 * 
 * [算出プロパティ]
 * 
 * [イベント処理]
 */
import { mapActions } from 'vuex'
import { move, moveToLinks } from '@/util'

export default {
    name:'PublicHouseOpening',
    methods:{
        ...mapActions({
            update:'links/update',
        }),
        moveTo(path){
            move(this.$router, path)
        },
        showLinks(){
            const selected = {
                selectedCategoryCode:1049,
                selectedCategoryName:'大部分が不透明材料で構成されている開口部（ドア等）',
                showingLevel1:'躯体の外皮性能等',
                showingLevel2:'開口部（仕様に基づく）',
                showingLevel3:null,
            }
            this.update(selected)
                .then(() => {
                    moveToLinks(this.$router, '/house/links/organizations')
                })
        },
    }
}
</script>

<style scoped>
.uq_opening{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 900px;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #CDD6DD;
}
.uq_opening>h4{
    width: 100%;
    margin: 8px 0;
}
.uq_opening a>p{
    display: inline-block;
}
.uq_opening >>> button.wd_200px{
    margin-left: auto;
}

a:hover{
    font-weight: 500;
}
.el_a__pdf{
    display: inline-flex;
    align-items: center;
    border-bottom: 1px #CDD6DD solid;
    font-weight: 600;
}
.el_a__pdf:after{
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    background: url(/img/icon_set.png);
    background-repeat: no-repeat;
    background-size: auto 14px;
    background-position-x: 0;
}
div.links{
    width:72.5%;
}
</style>