<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト' + title"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="true"
        :is-house="isHouse"
        :is-visible-breadcrumb="true"
    >
        <div :class="{'isHouse': isHouse}" class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">カテゴリー 一覧</h2>
                <hr class="hp_flexspacer"/>
                <p v-if="!isHouse">カテゴリーを選択し「検索」ボタンをクリックしてください。</p>
            </div>
            <div>
                <div class="bl_searchBox ly_searchBox">
                    <div class="bl_input_container">
                        <app-label
                            name="category1"
                            label="⼤分類"
                        />
                        <app-radio
                            v-model="level1"
                            value=""
                            label="全て"
                            ref="jest-links-level1-house"
                        />
                        <app-radio
                            v-if="isHouse"
                            v-model="level1"
                            value="躯体の外皮性能等"
                            label="躯体の外皮性能等"
                            ref="jest-links-level1-house"
                        />
                        <app-radio
                            v-if="!isHouse"
                            v-model="level1"
                            value="躯体の断熱性能"
                            label="躯体の断熱性能"
                            ref="jest-links-level1-building"
                        />
                        <app-radio
                            v-model="level1"
                            value="一次エネルギー消費量"
                            label="一次エネルギー消費量"
                        />
                        <app-radio
                            v-if="isHouse"
                            v-model="level1"
                            value="その他基準"
                            label="その他基準"
                        />
                    </div>
                    <hr class="hp_flexspacer" />
                    <div class="bl_input_container">
                        <app-label
                            name="category1"
                            label="中分類"
                        />
                        <app-select
                            v-model="level2"
                            :vmodel-label="level2"
                            :label="'全て'"
                            ref="jest-public-links-level2"
                        >
                            <app-select-option
                                :value="''"
                                v-model="level2"
                            >
                                全て
                            </app-select-option>
                            <app-select-option
                                v-for="(item, index) in categoriesLevelTwo"
                                :key="index"
                                :value="item"
                                v-model="level2"
                            >
                                {{item}}
                            </app-select-option>
                        </app-select>
                    </div>
                    <hr class="hp_flexspacer" />
                    <app-button
                        name="検索"
                        @click="updateList"
                        ref="jest-public-links-search"
                        class="inline wd_100px uq_publicSearch"
                    />
                </div>
                <div v-if="loaded">
                    <table v-if="categories.length !== 0" class="lists_table">
                        <thead>
                            <tr>
                                <th>中分類</th>
                                <th>小分類</th>
                                <th>カテゴリー名</th>
                                <th></th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in categories"
                                :key="'category' + index"
                                :ref="'jest-public-links-category' + index"
                            >
                                <td>{{item.level2}}</td>
                                <td>{{stringLevel3(item.level3)}}</td>
                                <td>{{item.category}}</td>
                                <td>
                                    <app-button
                                        name="表示"
                                        @click="showLinks(item)"
                                        :ref="'jest-public-links-show' + index"
                                        class="inline outlined wd_80px"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else class="bl_msgBox">該当する機器はありません</p>
                </div>
                <app-button
                    name="戻る"
                    class="cancel"
                    @click="back"
                    ref="jest-public-links-back"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P011 カテゴリー一覧（住宅版）
 * P021 カテゴリー一覧（非住宅版）
 *
 * [算出プロパティ]
 * title ... アクセスされたページの種類に合わせてタイトルを出し分ける
 *
 * [イベント処理]
 * 「情報提供事業者ログイン」ボタン押下時 ...  表示画面に応じたログイン画面へ遷移
 * 「一覧へ」ボタン押下時 ... 表示画面に応じたカテゴリー一覧へ遷移
 */
import { move, moveToLinks } from '@/util'
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'

export default {
    name:"PublicLinks",
    data(){
        return {
            loaded:false
        }
    },
    mounted(){
        this.updateList()
    },
    watch:{
        level1(){
            this.level2 = ''
        }
    },
    computed:{
        ...mapGetters({
            data:'links/categoryForSearch',
            categories:'links/categories',
            categoriesLevelTwo:'links/categoriesLevelTwo',
        }),
        ...mapComputedProperties([
            'level1',
            'level2'
        ]),
        isHouse(){
            return this.$route.path.includes('house')
        },
        title(){
            return this.isHouse ? '(住宅版)' : '(非住宅版)'
        },
    },
    methods:{
        ...mapActions({
            update:'links/update',
            updateCategories:'links/updateCategories',
            updateCategoriesLevels: 'links/updateCategoriesLevels'
        }),
        updateList:async function(){
            const loader = this.$loading.show()
            const type = this.isHouse ? 'house' : 'building'
            await Promise.all([
                this.updateCategories(type),
                this.updateCategoriesLevels(type)
            ])
                .then(() => {
                    this.loaded = true
                })
            loader.hide()
        },
        showLinks(item){
            this.update({
                    selectedCategoryCode:item.code,
                    selectedCategoryName:item.category,
                    showingLevel1:item.level1,
                    showingLevel2:item.level2,
                    showingLevel3:item.level3,
                })
                .then(() => {
                    if (item.code == 1042) {
                        move(this.$router, '/cogenerations')
                    } else if (item.code == 1032) {
                        move(this.$router, '/electric_heat_pumps')
                    } else if (item.code == 1037) {
                        move(this.$router, '/solar_water_heaters')
                    } else if (item.code == 1038) {
                        move(this.$router, '/solar_systems')
                    } else if (item.code == 1039) {
                        move(this.$router, '/hybrid_water_heaters')
                    } else if (item.code == 1049) {
                        move(this.$router, '/house/links/opening/door')
                    } else if (item.code == 1048) {
                        move(this.$router, '/house/links/opening/window')
                    } else if (item.code >= 2004 && item.code <= 2021) {
                        move(this.$router, '/building/links/opening')
                    } else {
                        moveToLinks(this.$router, this.$route.path + '/organizations')
                    }
                })
        },
        back(){
            move(this.$router, this.$route.path.replace('/links', ''))
        },
        stringLevel3(item){
            return item ?? '-'
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    width: 100%;
    min-width: 800px;
}
.bl_searchBox,
.lists_table{
    min-width: 100%;
}
.bl_contents_wrapper >>> .select_wrapper div.select_btn{
    width: 35em;
}
.isHouse.bl_contents_wrapper >>> .select_wrapper div.select_btn{
    width: 19em;
}
.bl_contents_wrapper >>> .select_wrapper ul.select{
    width: 100%;
}
.bl_contents_wrapper >>> .select_wrapper ul.select li{
    width: 100%;
}
.bl_searchBox button.el_button.inline.uq_publicSearch{
    margin-left: 50px;
}
table.lists_table thead th{
    top: 100px;
}
</style>