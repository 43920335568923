import Vue from 'vue'
import axios from '@/api'
import { isSet } from '@/util'

/**
 * リンクモジュール
 * @module store/modules/links
 */

// state
const state = {
    // 新着リンクの内容
    newLinks:[],
    links:[],
    linksForEvaluator:[],
    categories:[],
    categoriesTotal:0,
    level1:'',
    level2:'',
    level3:'',
    category:'',
    showingLevel1:null,
    showingLevel2:null,
    showingLevel3:null,
    selectedCategoryCode:null,
    selectedCategoryName:null,
    categoriesLevels:{},
    type:'',
    category_code:'',
    url:'',
    memo:'',
    for_evaluator:false,
    id:'',
    page:1,
    total:0,
    material:null,
    glass:null,
    glasses:[],
    number:null,
    password:null,
    searchWithCategory:false,
    houseMaterial:'木製建具又は樹脂製建具',
    houseGlass:'三層複層ガラス（Low-E2枚、断熱ガス、日射取得型）',
    houseMaterialCategories:[],
    houseGlassCategories:[],
}

const getters = {
    newLinks(state){
        return state.newLinks
    },
    categories(state){
        return state.categories
    },
    materialCategories(state){
        return state.categories.filter(x => {
            return x.level2 === '開口部（仕様に基づく）' && x.level3 === '建具'
        })
    },
    glassCategories(state){
        return state.categories.filter(x => {
            return x.level2 === '開口部（仕様に基づく）' && x.level3 === 'ガラス'
        })
    },
    houseMaterialCategories(state){
        return state.houseMaterialCategories
    },
    houseGlassCategories(state){
        return state.houseGlassCategories
    },
    selectedCategoryCode(state){
        return state.selectedCategoryCode
    },
    categoriesLevelTwo(state){
        return state.categoriesLevels[state.level1] ? Object.keys(state.categoriesLevels[state.level1]) : {}
    },
    categoriesLevelThree(state){
        return state.categoriesLevels[state.level1] && state.categoriesLevels[state.level1][state.level2] ? state.categoriesLevels[state.level1][state.level2] : {}
    },
    categoryForSearch(state){
        return {
            level1:state.level1,
            level2:state.level2
        }
    },
    categoryForCreateLinks(state){
        return {
            level1:state.level1,
            level2:state.level2,
            level3:state.level3,
            category:state.category,
            searchWithCategory:state.searchWithCategory,
        }
    },
    dataForCreateLinks(state){
        return {
            category_code:state.category_code,
            url:state.url,
            memo:state.memo,
            for_evaluator:state.for_evaluator
        }
    },
    links(state){
        return state.links
    },
    linksForEvaluator(state){
        return state.linksForEvaluator
    },
    selectedCategoryName(state){
        return state.selectedCategoryName
    },
    showingLevel(state){
        return {
            level1:state.showingLevel1,
            level2:state.showingLevel2,
            level3:state.showingLevel3
        }
    },
    id (state) {
        return state.id
    },
    page (state) {
        return state.page
    },
    total (state) {
        return state.total
    },
    searchBuildingOpeningparams (state) {
        return {
            material:state.material,
            glass:state.glass,
        }
    },
    searchHouseOpeningparams (state) {
        return {
            houseMaterial:state.houseMaterial,
            houseGlass:state.houseGlass,
        }
    },
    glasses (state) {
        return state.glasses
    },
    evaluatorAuthParameter (state) {
        return {
            number:state.number,
            password:state.password
        }
    },
    validation (state) {
        return isSet(state.category_code) && isSet(state.url)
    },
    validationError (state) {
        const errors = {}
        if (!isSet(state.category_code)) {
            errors.category_code = ['カテゴリーコードは必須です']
        }
        if (!isSet(state.url)) {
            errors.url = ['urlは必須です']
        }
        return {
            status:422,
            data:{
                errors
            }
        }
    }
}

const actions = {
    // newLinksを取得し更新
    updateNewLinks({commit}, type){
        return axios.get('/api/links', {params:{
            new:true,
            approved:true,
            type,
        }})
        .then(response => {
            commit('update', {newLinks:response.data.links})
            return response
        }, reject => {
            return reject.response
        })
    },
    // 一般用linksを取得し更新
    updateLinks({state, commit}, {type, for_evaluator}){
        const params = {
            category_code:state.selectedCategoryCode,
            type,
            approved:true,
            for_evaluator,
            number:state.number,
            password:state.password,
        }
        return axios.get('/api/links', {params})
            .then(response => {
                // カテゴリーの情報を更新する
                let category = response.data.category
                if (category != null) {
                    commit('update', {
                        selectedCategoryCode:category.code,
                        selectedCategoryName:category.category,
                        showingLevel1:category.level1,
                        showingLevel2:category.level2,
                        showingLevel3:category.level3,
                    })
                }

                if(for_evaluator) {
                    commit('update', {linksForEvaluator:response.data.links})
                } else {
                    commit('update', {links:response.data.links})
                }
                return response
            }, reject => {
                return reject.response
            })
    },
    // 一般用linksのうち窓を取得し更新
    updateWindowLinks({state, commit}, {for_evaluator}){
        return axios.get('/api/links/windows', {params:{
            for_evaluator,
            approved:true,
            number:state.number,
            password:state.password,
        }})
            .then(response => {
                if(for_evaluator) {
                    commit('update', {linksForEvaluator:response.data.links})
                } else {
                    commit('update', {links:response.data.links})
                }
                return response
            }, reject => {
                return reject.response
            })
    },
    // 一般用linksのうちドアを取得し更新
    updateDoorLinks({state, commit}, {for_evaluator}){
        return axios.get('/api/links/doors', {params:{
            for_evaluator,
            approved:true,
            number:state.number,
            password:state.password,
        }})
            .then(response => {
                if(for_evaluator) {
                    commit('update', {linksForEvaluator:response.data.links})
                } else {
                    commit('update', {links:response.data.links})
                }
                return response
            }, reject => {
                return reject.response
            })
    },
    // 事業者用linksを取得し更新
    updateLinksForOrganization({commit}, {organization_id, page}){
        commit('update', {page})
        const params = {
            organization_id,
            page
        }
        return axios.get('/api/links', {params})
            .then(response => {
                commit('update', {
                    links:response.data.links,
                    total:response.data.total
                })
                return response
            }, reject => {
                return reject.response
            })
    },
    // 一覧画面用のcategoriesを取得し更新
    updateCategories({state, commit}, type){
        const params = {
            type
        }
        if (isValidLevel1(type, state.level1) && state.searchWithCategory === false) {
            params.level1 = state.level1
            if(state.level2 !== '') {
                params.level2 = state.level2
                if(state.level3 !== '') {
                    params.level3 = state.level3
                }
            }
        }
        if(state.category !== '' && state.searchWithCategory) {
            params.category = state.category.trim()
        }
    return axios.get('/api/links/categories', {params})
        .then(response => {
            // オブジェクトの配列になっていたりオブジェクト入りのオブジェクトになっていたりでレスポンスが一定しないので形を整える
            const categories = Object.keys(response.data.categories).map(key => {
                return response.data.categories[key]
            })

            commit('update', {
                categories,
                categoriesTotal:response.data.total
            })
        })
    },
    // 一覧画面用のcategoriesLevelsを取得し更新
    updateCategoriesLevels({commit}, type){
        const params = {
            type
        }
        return axios.get('/api/links/categories/levels', {params})
        .then(response => {
            commit('update', {
                categoriesLevels:response.data.levels,
                type
            })
        })
    },
    update({commit}, values){
        commit('update', values)
    },
    createLink({state}, type){
        const params = {
            category_code:state.category_code,
            url:state.url,
            type:type,
            for_evaluator:state.for_evaluator,
        }
        if (state.for_evaluator) {
            params.memo = state.memo
        }
        return axios.post('/api/links', params)
    },
    editLink({state}, {id, type}){
        const params = {
            category_code:state.category_code,
            url:state.url,
            type:type,
            for_evaluator:state.for_evaluator,
        }
        if (state.for_evaluator) {
            params.memo = state.memo
        }
        return axios.put('/api/links/' + id, params)
    },
    deleteLink(state, id){
        return axios.delete('/api/links/' + id)
    },
    getGlasses({commit}, params) {
        return axios.get('/api/links/glasses', {params})
            .then(response => {
                commit('update', {glasses:Object.values(response.data.glasses)})
                return response
            }, reject => {
                return reject.response
            })
    },
    getOpenings({commit}){
        return axios.get('/api/links/house/opening/categories')
            .then(response => {
                commit('update', {
                    houseGlassCategories:response.data.glasses,
                    houseMaterialCategories:response.data.materials
                })
                return response
            }, reject => {
                return reject.response
            })
    },
}

const mutations = {
    // stateを更新する
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

function isValidLevel1(type, level1) {
    if(level1 === '') {
        return false
    } else if ((level1 === 'その他基準' || level1 === '躯体の外皮性能等') && type === 'building') {
        return false
    } else if ((level1 === '躯体の断熱性能') && type === 'house') {
        return false
    } else {
        return true
    }
}